import { ref, Ref } from "vue";

import { Module } from "../../common/enum.module";
import { useCommon } from "../../composition-functions/common";
import { IGetMandantParams, IGetMandantsParams, IUpdateModulesParams } from "../../composition-functions/mandant/IParams";
import { MandantAttributeType } from "../../enums/MandantAttributeType";
import { State } from "../../enums/State";
import { IQueryListResult } from "../../interfaces/IResponses";
import { assertNever } from "../../util/assertNever";
import { IDeleteMandantParams, IGetMandantsByIdsParams, IRefreshCurrentMandantParams, ISaveMandantParams } from "./IParams";
import { IGetMandantsByIdsResponse } from "./IResponses";
import { MandantDto } from "./MandantDto";

const currentMandant: Ref<null | MandantDto> = ref(null);

async function getMandant({ mandantId }: IGetMandantParams): Promise<null | MandantDto> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/mandants/${mandantId}` });
  const result = response.json();
  return result;
}

async function refreshCurrentMandant({ mandantId }: IRefreshCurrentMandantParams): Promise<void> {
  currentMandant.value = await getMandant({
    mandantId,
  });
}

async function deleteMandant({ mandantId }: IDeleteMandantParams) {
  const { deleteRequest } = useCommon();
  await deleteRequest({ path: `/admin/mandants/${mandantId}` });
}

async function getMandantsByIds({ mandantIds }: IGetMandantsByIdsParams): Promise<IQueryListResult<IGetMandantsByIdsResponse>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams({
    mandantIds: mandantIds.toString(),
  });

  const response = await getRequest({ path: "/admin/mandants/getByIds", urlSearchParams });
  const result = await response.json();
  return result;
}

async function getMandants({ currentPage, pageSize, searchTerm }: IGetMandantsParams): Promise<IQueryListResult<MandantDto>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams({
    searchTerm,
    currentPage: currentPage.toString(),
    pageSize: pageSize.toString(),
  });

  const response = await getRequest({ path: "/admin/mandants", urlSearchParams });
  const result = await response.json();
  return result;
}

async function saveMandant({ mandant }: ISaveMandantParams): Promise<{ id: number }> {
  const { postRequest } = useCommon();

  const response = await postRequest({ path: "/admin/mandants", data: JSON.stringify(mandant) });
  const result = await response.json();
  return result;
}

async function getMandantFilterOptions(searchTerm: null | string) {
  const getMandantsResult = await getMandants({
    currentPage: 0,
    pageSize: 20,
    searchTerm: searchTerm ?? "",
  });

  return getMandantsResult.items.map((mandant) => {
    return {
      value: mandant.id,
      label: `${mandant.id} - ${mandant.name}`,
    };
  });
}

async function updateModules({ mandantId, modules }: IUpdateModulesParams) {
  const { putRequest } = useCommon();

  const moduleInputDtos = [];

  for (const module of modules) {
    switch (module.moduleType) {
      case Module.CONNECT: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            type: module.settings.type,
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }
      case Module.CRM_SYNC: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            type: module.settings.type,
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }
      case Module.NEWSLETTER_SYNC: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            type: module.settings.type,
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }
      case Module.REQUEST:
      case Module.CHECKIN: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
            shouldHideBrandingLabel: module.settings.shouldHideBrandingLabel ?? false,
          },
        });
        break;
      }
      case Module.DISCOUNT_CODE:
      case Module.LTS_ASTAT:
      case Module.LTS_TICKETING:
      case Module.REST_API:
      case Module.YANOVIS:
      case Module.VOUCHER:
      case Module.LTS_SUEDTIROL_GUEST_PASS: {
        moduleInputDtos.push({
          moduleType: module.moduleType,
          settings: {
            state: module.settings.state,
            testPhaseUntil: module.settings.testPhaseUntil,
          },
        });
        break;
      }

      default: {
        assertNever(module);
      }
    }
  }

  await putRequest({
    path: `/admin/mandants/${mandantId}/modules`,
    data: JSON.stringify({ modules: moduleInputDtos }),
  });
}

type ModuleActivationDetails =
  | {
      moduleType: Module;
      isActivatable: false;
      message: string;
    }
  | {
      moduleType: Module;
      isActivatable: true;
    };
async function getModuleActivationDetails(mandantId: number): Promise<ModuleActivationDetails[]> {
  const { getRequest } = useCommon();

  const response = await getRequest({ path: `/admin/mandants/${mandantId}/modules/activationDetails` });
  const result = await response.json();
  return result;
}

function doesCurrentMandantHaveActiveAttribute(attributeKey: MandantAttributeType): boolean {
  return (
    currentMandant.value?.attributes.some((attribute) => attribute.key === attributeKey && attribute.value === "true") ?? false
  );
}

function doesCurrentMandantHaveActiveModule(moduleType: Module): boolean {
  if (!currentMandant.value) return false;
  const foundModule = currentMandant.value.modules.find((module) => module.moduleType === moduleType);
  return foundModule !== undefined && foundModule.settings.state === State.ACTIVE;
}

export const useMandant = () => {
  return {
    currentMandant,
    getMandant,
    getMandantsByIds,
    getMandantFilterOptions,
    getMandants,
    saveMandant,
    deleteMandant,
    refreshCurrentMandant,
    updateModules,
    getModuleActivationDetails,
    doesCurrentMandantHaveActiveAttribute,
    doesCurrentMandantHaveActiveModule,
  };
};
