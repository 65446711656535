import { ref, watch } from "vue";
import { useCommon } from "../composition-functions/common";
import { useMandant } from "../composition-functions/mandant";
import { PaginationQueryResult } from "../dtos/PaginationQueryResult";
import { IQueryParams } from "../interfaces/IParams";
import { NotificationDto } from "../notification/dto.notification";

const { currentMandant } = useMandant();

export async function refreshUnreadNotificationCountForCurrentMandant(): Promise<{ success: boolean }> {
  if (!currentMandant.value) {
    unreadNotificationCount.value = null;
    return { success: true };
  }

  const { getRequest } = useCommon();
  try {
    const response = await getRequest({ path: `/admin/mandants/${currentMandant.value.id}/notifications/unreadCount` });
    const result = await response.json();
    unreadNotificationCount.value = result;
    return { success: true };
  } catch (error) {
    return { success: false };
  }
}

type GetNotificationParams = { mandantId: number; queryParams?: IQueryParams };
export async function getNotifications({
  mandantId,
  queryParams,
}: GetNotificationParams): Promise<PaginationQueryResult<NotificationDto>> {
  const { getRequest } = useCommon();

  const urlSearchParams = new URLSearchParams();

  if (queryParams) {
    urlSearchParams.append("searchTerm", queryParams.searchTerm);
    urlSearchParams.append("currentPage", queryParams.currentPage.toString());
    urlSearchParams.append("pageSize", queryParams.pageSize.toString());
  }

  const response = await getRequest({ path: `/admin/mandants/${mandantId}/notifications`, urlSearchParams });
  const result = await response.json();
  return result;
}

type SetNoticationFlagParams = { mandantId: number; notificationIds: number[] };

type SetWasReadForNotificationsParams = { wasRead: boolean; keepalive?: boolean } & SetNoticationFlagParams;
export async function setWasReadForNotifications({
  mandantId,
  notificationIds,
  wasRead,
  keepalive,
}: SetWasReadForNotificationsParams): Promise<NotificationDto[]> {
  const { putRequest } = useCommon();

  const response = await putRequest({
    path: `/admin/mandants/${mandantId}/notifications/setWasRead`,
    data: JSON.stringify({ notificationIds, wasRead }),
    keepalive,
  });
  const result = await response.json();

  refreshUnreadNotificationCountForCurrentMandant();

  return result;
}

export async function deleteNotifications({ notificationIds, mandantId }: SetNoticationFlagParams): Promise<void> {
  const { deleteRequest } = useCommon();

  await deleteRequest({
    path: `/admin/mandants/${mandantId}/notifications`,
    data: JSON.stringify({ notificationIds }),
  });

  refreshUnreadNotificationCountForCurrentMandant();

  return;
}

let interval: undefined | number;
const unreadNotificationCount = ref<null | number>(null);

watch(currentMandant, async (newValue, oldValue) => {
  if (!newValue || !oldValue || newValue.id !== oldValue.id) {
    unreadNotificationCount.value = null;
    refreshUnreadNotificationCountForCurrentMandant();
  }
});
export function useNotifications() {
  if (!interval) {
    interval = setInterval(refreshUnreadNotificationCountForCurrentMandant, 1000 * 60);
  }

  return {
    unreadNotificationCount,
    getNotifications,
    deleteNotifications,
    refreshUnreadNotificationCountForCurrentMandant,
    setWasReadForNotifications,
  };
}
