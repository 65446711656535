import { createI18n } from "vue-i18n";

import dayjs from "dayjs";
import locale_de from "dayjs/locale/de";
import locale_it from "dayjs/locale/it";
import { Language } from "../composition-functions/language/Language";
import { LanguageIsoCode } from "../composition-functions/language/LanguageIsoCode";
import de from "../i18n/de.json";
import it from "../i18n/it.json";
import { IGetGlobalTranslationParams } from "./IParams";

export const i18n = createI18n({
  legacy: false,
  locale: LanguageIsoCode.GERMAN,
  fallbackLocale: LanguageIsoCode.GERMAN,
  messages: {
    [LanguageIsoCode.GERMAN]: de,
    [LanguageIsoCode.ITALIAN]: it,
  },
});

export const changeI18nLanguage = (language: Language): void => {
  i18n.global.locale.value = language === Language.ITALIAN ? LanguageIsoCode.ITALIAN : LanguageIsoCode.GERMAN;
  dayjs.locale(language === Language.ITALIAN ? locale_it : locale_de);
};

export const getGlobalTranslation = ({ message, parameters }: IGetGlobalTranslationParams): string => {
  return parameters !== undefined ? i18n.global.t(message, parameters) : i18n.global.t(message);
};
